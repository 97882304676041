import styled from 'styled-components';
import { themeRequire } from "../../../components/theme-utils"

const PricingTable = styled.div`
  border: 1px solid ${themeRequire('colors.primaryTransparents.3')};
  border-radius: 5px;
  padding: 60px 45px;
  border-radius: 5px;
  @media (max-width: 767px) {
    padding: 45px 35px;
  }
`;

const PricingHead = styled.div`
  margin-bottom: 40px;
`;

const PricingPrice = styled.div`
  margin-bottom: 30px;
`;

const PricingButton = styled.div`
  text-align: center;
  margin-bottom: 55px;
`;

const PricingList = styled.div``;

const ListItem = styled.div`
  display: flex;
  margin-bottom: 19px;
  &:last-child {
    margin-bottom: 0;
  }
  .price_list_icon {
    color: ${themeRequire('colors.primary')};
    margin-right: 10px;
  }
`;

const PricingButtonWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  .reusecore__button {
    font-size: 16px;
    font-weight: 400;
    color: ${themeRequire('colors.labelColor')};
    background: ${themeRequire('colors.white')};
    height: 50px;
    width: 165px;
    border: 1px solid ${themeRequire('colors.primaryTransparents.3')};
    &:nth-child(1) {
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 5px;
      border-right-color: transparent;
    }
    &:nth-child(2) {
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 0;
      border-left-color: transparent;
    }
    &.active-item {
      color: ${themeRequire('colors.primary')};
      border-color: ${themeRequire('colors.primary')};
    }
    @media (max-width: 575px) {
      font-size: 14px;
      height: 44px;
      width: 120px;
      padding: 0 5px;
    }
  }
`;

export {
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  PricingButtonWrapper,
};
export default PricingTable;
