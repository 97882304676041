import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import Heading from '../../../components/Heading';
import 'react-image-gallery/styles/css/image-gallery.css';
import Container from '../../../components/UI/Container';
import FeatureSliderWrapper from './featureSlider.style';
import { graphql, useStaticQuery } from "gatsby"

const FeatureSlider = ({ sectionSubTitle, sectionTitle, sectionHeader }) => {
  const { indexCommandsSectionYaml: section, allIndexCommandsListYaml: { nodes: cmds } } = useStaticQuery(graphql`
    query {
      indexCommandsSectionYaml {
        title
        heading
      }
      allIndexCommandsListYaml {
        nodes {
          title
          thumbnail {
            publicURL
          }          
          image {
            publicURL
          }
        }
      }
    }
  `);

  const images = cmds.map((cmd) => ({
    original: cmd.image.publicURL,
    thumbnail: cmd.thumbnail.publicURL,
    thumbnailLabel: cmd.title,
  }));

  return (
    <FeatureSliderWrapper>
      <div className="FeatureSliderInner">
        <span> </span>
        <span> </span>
        <span> </span>
      </div>
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} content={section.title} />
          <Heading {...sectionTitle} content={section.heading} />
        </Box>
        <Box className="FeatureSlider">
          <ImageGallery
            items={images}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            showBullets={true}
            autoPlay={true}
          />
        </Box>
      </Container>
    </FeatureSliderWrapper>
  );
};

// FeatureSlider style props
FeatureSlider.propTypes = {
  sectionSubTitle: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionHeader: PropTypes.object,
};

// FeatureSlider default style
FeatureSlider.defaultProps = {
  sectionHeader: {},
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.13em',
    fontWeight: '700',
    color: 'danger',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '24px', '24px', '30px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
  },
};

export default FeatureSlider;
