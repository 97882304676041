import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import Image from '../../../components/Image';
import Container from '../../../components/UI/Container';

import PartnerSectionWrapper from './ticketChannels.style';
import { graphql, useStaticQuery } from "gatsby"

const TicketChannelsSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
}) => {
  const { indexTicketChannelsSectionYaml: section } = useStaticQuery(graphql`
    query {
      indexTicketChannelsSectionYaml {
        banner {
          image {
            publicURL
          }
          alt
        }
        heading
        details
        callToAction {
          label
          href
        }
      }
    }
  `);

  return (
    <PartnerSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            <Image src={section.banner.image.publicURL} alt={section.banner.alt} />
          </Box>
          <Box {...col} {...textArea}>
            <Heading {...title} content={section.heading} />
            <Text {...description} content={section.details} />
            <Box>
              <a href={section.callToAction.href}>
                <Button {...button} title={section.callToAction.label} />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

TicketChannelsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

TicketChannelsSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'warningWithBg',
    minWidth: '150px',
  },
};

export default TicketChannelsSection;
