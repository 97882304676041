import React, { Fragment, useEffect, useState } from "react"
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from '../contexts/DrawerContext';
import { theme } from '../theme';
import { ResetCSS } from '../assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
  ConditionWrapper
} from "../containers/index.style"

import BannerSection from '../containers/Index/Banner';
import Navbar from '../containers/Index/Navbar';
import PricingSection from '../containers/Index/Pricing';
import TicketChannelsSection from '../containers/Index/TicketChannelsSection';
import InfoSection from '../containers/Index/Info';
import FeatureSection from '../containers/Index/Feature';
import TestimonialSection from '../containers/Index/Testimonial';
import Footer from '../containers/Common/Footer';
import SEO from '../components/seo';
import FeatureSlider from "../containers/Index/FeatureSlider"
import FeatureSectionTwo from "../containers/Index/FeatureTwo"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"

library.add(fas);

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

export default () => {

  const size = process.browser && useWindowSize();
  const innerWidth = process.browser && size.innerWidth;

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <SEO />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <TicketChannelsSection />
          <ConditionWrapper id="keyfeature">
            {innerWidth > 1100 ? <FeatureSlider /> : <FeatureSectionTwo />}
          </ConditionWrapper>
          <InfoSection />
          <FeatureSection />
          <PricingSection />
          <TestimonialSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
