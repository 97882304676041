import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import Image from "../../../components/Image"
import FeatureBlock from "../../../components/FeatureBlock"
import Container from "../../../components/UI/Container"
import FeatureSectionWrapper from "./featureSection.style"
import { graphql, useStaticQuery } from "gatsby"

const FeatureSectionTwo = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  const { indexCommandsSectionYaml: section, allIndexCommandsListYaml: { nodes: cmds } } = useStaticQuery(graphql`
    query {
      indexCommandsSectionYaml {
        title
        heading
      }
      allIndexCommandsListYaml {
        nodes {
          title
          thumbnail {
            publicURL
          }          
        }
      }
    }
  `);

  return (
    <FeatureSectionWrapper id="keyfeatures">
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} content={section.title} />
          <Heading {...sectionTitle} content={section.heading} />
        </Box>
        <Box className="row" {...row}>
          {cmds.map((cmd, index) => (
            <Box className="col" {...col} key={index}>
              <Fade bottom delay={index * 120}>
                <FeatureBlock
                  icon={
                    <Image src={cmd.thumbnail.publicURL} alt="Demo Image" />
                  }
                  wrapperStyle={blockWrapperStyle}
                  iconStyle={iconStyle}
                  contentStyle={contentStyle}
                  title={<Heading content={cmd.title} {...featureTitle} />}
                />
              </Fade>
            </Box>
          ))}
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

// FeatureTwo style props
FeatureSectionTwo.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// FeatureTwo default style
FeatureSectionTwo.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['56px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.13em',
    fontWeight: '700',
    color: 'danger',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '24px', '24px', '30px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1 / 2, 1 / 2, 1 / 3, 1 / 3, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['10px', '20px', '20px', '40px'],
  },
  // feature icon default style
  iconStyle: {
    width: '75px',
    height: '75px',
    m: '0 auto',
    borderRadius: '50%',
    bg: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '32px',
    color: 'primaries.3',
    overflow: 'hidden',
    mb: '15px',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'center',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['16px', '18px'],
    fontWeight: '400',
    color: 'labelColor',
    lineHeight: '1.5',
    mb: '20px',
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: ['14px', '15px'],
    lineHeight: '1.84',
    color: 'textColor',
  },
};

export default FeatureSectionTwo;
