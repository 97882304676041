import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import Container from '../../../components/UI/Container';
import TiltShape from '../../Common/TiltShape';
import { BannerWrapper, DiscountWrapper, DiscountLabel } from './banner.style';

const BannerSection = ({
  row,
  contentWrapper,
  discountAmount,
  discountText,
  title,
  description,
  imageWrapper,
  buttonWrapper,
  fillButton,
}) => {
  const { indexBannerSectionYaml: section } = useStaticQuery(graphql`
    query {
      indexBannerSectionYaml {
        banner {
          image {
            childImageSharp {
              fluid(maxWidth: 1170, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          alt
        }
        trial {
          time
          title
        }
        heading
        subheading
        callToAction {
          label
          href
        }
      }
    }
  `);

  return (
    <BannerWrapper id="banner_section">
      <TiltShape />
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <DiscountWrapper>
              <DiscountLabel>
                <Text {...discountAmount} content={section.trial.time} />
                <Text {...discountText} content={section.trial.title} />
              </DiscountLabel>
            </DiscountWrapper>
            <Heading {...title} content={section.heading} />
            <Text {...description} content={section.subheading} />
            <Box {...buttonWrapper}>
              <a href={section.callToAction.href}>
                <Button {...fillButton} title={section.callToAction.label} />
              </a>
            </Box>
          </Box>
          <Box {...imageWrapper}>
            <Fade bottom>
              <Image
                fluid={section.banner.image.childImageSharp.fluid}
                alt={section.banner.alt}
              />
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '80%', '55%', '50%'],
    mb: '40px',
  },
  imageWrapper: {
    width: '100%',
  },
  title: {
    fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: 'white',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  description: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'white',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center',
  },
  discountAmount: {
    fontSize: ['12px', '16px', '20px', '21px', '23px'],
    fontWeight: '700',
    color: 'warning',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['12px', '16px', '20px', '21px', '23px'],
    fontWeight: '700',
    color: 'white',
    mb: 0,
    as: 'span',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'dangerWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px',
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'white',
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
};

export default BannerSection;
